angular.module("app")
    .factory('_mapFieldService', function ($rootScope, $compile, _mapService, _v, _flashService) {
        var _mapFieldService = Object.create(_mapService);

        _mapFieldService.highlightField = highlightField;
        _mapFieldService.deleteField = deleteField;
        _mapFieldService.setTool = setTool;
        _mapFieldService.editMap = editMap;
        _mapFieldService.editMapOff = editMapOff;
        _mapFieldService.addCoordinates = addCoordinates;
        _mapFieldService.setupUtils = setupUtils;
        _mapFieldService.fieldChanged = fieldChanged;
        _mapFieldService.panToLatLngZoom = panToLatLngZoom;
        _mapFieldService.editField = editField;
        _mapFieldService.switchNDVIOverlay = switchNDVIOverlay;
        _mapFieldService.setNDVIData= setNDVIData;

        // shortcut
        var CONSTANT = _mapService.CONSTANT;
        var vars = _mapService.vars;

        // Google Map Drawing Manager
        var drawingManager = null;

        var editModel = {editMap: false};

        var geometryFactory = new jsts.geom.GeometryFactory();

        var highlightEnabled = true;

        var SCOUT_POINT_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 5, strokeColor: "#FFFFFF", strokeWeight: 1, fillColor: "#A8DC63", fillOpacity: 1};

        var SELECTED_CELL = {fillOpacity: 0.75, fillColor: "#fff470"};

        var START_ICON = {
            path: 'm 1280,576 v 128 q 0,26 -19,45 -19,19 -45,19 H 714 l 189,189 q 19,19 19,45 0,26 -19,45 l -91,91 q -18,18 -45,18 -27,0 -45,-18 L 360,776 269,685 q -18,-18 -18,-45 0,-27 18,-45 l 91,-91 362,-362 q 18,-18 45,-18 27,0 45,18 l 91,91 q 18,18 18,45 0,27 -18,45 L 714,512 h 502 q 26,0 45,19 19,19 19,45 z m 256,64 Q 1536,431 1433,254.5 1330,78 1153.5,-25 977,-128 768,-128 559,-128 382.5,-25 206,78 103,254.5 0,431 0,640 0,849 103,1025.5 206,1202 382.5,1305 559,1408 768,1408 977,1408 1153.5,1305 1330,1202 1433,1025.5 1536,849 1536,640 z',
            fillColor: '#32BC4B',
            fillOpacity: 1,
            scale: 0.02,
            strokeColor: '#FFFFFF',
            strokeWeight: 2,
            anchor: new google.maps.Point(650, 650)
        };

        //returns field area in m2
        _mapFieldService.getArea = function(field) {
            var shape = vars.fieldShapes[field.id];
            return google.maps.geometry.spherical.computeArea(shape.polygon.getPath());
        };

        var scoutGrid = [];
        var startMarker;
        var scoutPoints = [];
        var combinedCells = [];

        _mapFieldService.clearLoads = function() {
            scoutGrid.forEach(cell => {
                if (cell.cellRect) {
                    cell.cellRect.setMap(null);
                }
                if (cell.selected) {
                    cell.setOptions(SELECTED_CELL);
                }
            });
        };

        _mapFieldService.fieldArea = function(fieldId) {
            return google.maps.geometry.spherical.computeArea(_mapService.vars.fieldShapes[fieldId].polygon.getPath());
        };

        _mapFieldService.isSimple = function(fieldId) {
            var fieldShape = vars.fieldShapes[fieldId];
            if (!_.isUndefined(fieldShape)) {
                var fieldPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(fieldShape.polygon.getPath())));
                return fieldPolygon.isSimple();
            } else {
                return true;
            }
        };

        _mapFieldService.loadArea = function(load, fieldId) {
            var result = 0;

            var fieldPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(_mapService.vars.fieldShapes[fieldId].polygon.getPath())));
            load.cells.forEach(cell => {
                var googleCell = _.find(scoutGrid, function(c){ return c.loadIndex[0] == cell[0] && c.loadIndex[1] == cell[1]; });
                var cellPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(googleCell.getPath())));
                var cellOnField = fieldPolygon.intersection(cellPolygon);
                result = result + google.maps.geometry.spherical.computeArea(jts2Path(cellOnField.getCoordinates()));
            });

            return result;
        };

        // cell:
        // p1----------------p2
        // |                  |
        // p4----------------p3
        _mapFieldService.paintLoads = function(loads, gridData) {
            loads.forEach(load => {

                var firstCell = _.find(scoutGrid, function(c){ return c.loadIndex[0] == load.cells[0][0] && c.loadIndex[1] == load.cells[0][1]; });
                var p1, p2, p3, p4;
                if (["p1p4", "p4p1"].includes(loadConfig.edge)) {
                    p1 = firstCell.getPath().getAt(0);
                    p2 = google.maps.geometry.spherical.computeOffset(p1, gridData.dy, gridData.angle);
                    p3 = google.maps.geometry.spherical.computeOffset(p2, gridData.dy, gridData.angle + 90);
                    p4 = google.maps.geometry.spherical.computeOffset(p1, gridData.dy, gridData.angle + 90);
                } else {
                    p1 = firstCell.getPath().getAt(1);
                    p2 = firstCell.getPath().getAt(2);
                    p3 = google.maps.geometry.spherical.computeOffset(p2, - gridData.dy, gridData.angle);
                    p4 = google.maps.geometry.spherical.computeOffset(p1, - gridData.dy, gridData.angle);
                }

                firstCell.cellRect = new google.maps.Polygon({map: _mapService.vars.gmap, path: [p1, p2, p3, p4, p1], options: {strokeWeight: 0, fillOpacity: 1, fillColor: "#154755", zIndex: CONSTANT.ZINDEX.FIRST_CELL}});

                load.cells.forEach(cell => { //j i
                    var color = load.color;
                    if (cell[2] == 1) { //done spray
                        color = "#809179";
                    }
                    scoutGrid[cell[1] * gridData.ny + cell[0]].setOptions({ fillColor: color, fillOpacity: 0.85 });
                });
            });
        };

        var loadConfig;
        // grid:
        // p1----------------p2
        // |                  |
        // p4----------------p3
        _mapFieldService.startLoads = function(gridData) {

            var p1 = new google.maps.LatLng(gridData.lat0, gridData.lon0);
            var p2 = google.maps.geometry.spherical.computeOffset(p1, gridData.dx * gridData.nx, gridData.angle);
            var p3 = google.maps.geometry.spherical.computeOffset(p2, gridData.dy * gridData.ny, gridData.angle + 90);
            var p4 = google.maps.geometry.spherical.computeOffset(p1, gridData.dy * gridData.ny, gridData.angle + 90);

            loadConfig = {distanceToStartPoint: google.maps.geometry.spherical.computeDistanceBetween(startMarker.getPosition(), p1), edge: "p1p4", row: 0};
            var d = google.maps.geometry.spherical.computeDistanceBetween(startMarker.getPosition(), p2);
            if (d < loadConfig.distanceToStartPoint) {
                loadConfig.distanceToStartPoint = d;
                loadConfig.edge = "p2p3";
            }
            d = google.maps.geometry.spherical.computeDistanceBetween(startMarker.getPosition(), p3);
            if (d < loadConfig.distanceToStartPoint) {
                loadConfig.distanceToStartPoint = d;
                loadConfig.edge = "p3p2";
                loadConfig.row = gridData.ny - 1;
            }
            d = google.maps.geometry.spherical.computeDistanceBetween(startMarker.getPosition(), p4);
            if (d < loadConfig.distanceToStartPoint) {
                loadConfig.distanceToStartPoint = d;
                loadConfig.edge = "p4p1";
                loadConfig.row = gridData.ny - 1;
            }

            return getTwoRows(gridData);
        };

        _mapFieldService.nextCells = function(gridData) {
            return getTwoRows(gridData);
        };

        function getTwoRows(gridData) {
            var result = getOneRow(gridData, 0);
            result = result.concat(getOneRow(gridData, 1));
            return result;
        }

        function getOneRow(gridData, direction) {
            var result = [];

            if (["p1p4", "p2p3"].includes(loadConfig.edge)) {
                if (loadConfig.row > gridData.ny-1) {
                    return [];
                }
            } else {
                if (loadConfig.row < 0) {
                    return [];
                }
            }

            var xDirection = direction;
            if (["p2p3", "p3p2"].includes(loadConfig.edge)) {
                xDirection = (direction == 1 ? 0 : 1); //invert direction
            }

            if (xDirection == 0) {
                for (var k = 0; k < gridData.nx; k++) {
                    var cell = _.find(scoutGrid, function(c){ return c.loadIndex[0] == loadConfig.row && c.loadIndex[1] == k; }); //cell.loadIndex = [j, i, 0];
                    if (cell && cell.selected) {
                        result.push(cell.loadIndex);
                    }
                }
            } else {
                for (var k = gridData.nx - 1; k >=0 ; k--) {
                    var cell = _.find(scoutGrid, function(c){ return c.loadIndex[0] == loadConfig.row && c.loadIndex[1] == k; }); //cell.loadIndex = [j, i, 0];
                    if (cell && cell.selected) {
                        result.push(cell.loadIndex);
                    }
                }
            }

            if (["p1p4", "p2p3"].includes(loadConfig.edge)) {
                loadConfig.row = loadConfig.row + 1;
            } else {
                loadConfig.row = loadConfig.row - 1;
            }

            if (_.size(result) == 0) {
                result = getOneRow(gridData, direction);
            }

            return result;
        }

        _mapFieldService.hideAllFieldsExceptOne = function(f) {
            highlightEnabled = false;
            fieldId = f;
            Object.keys(vars.fieldShapes).forEach(id => {
                if (id.toString() != f.toString()) {
                    vars.fieldShapes[id].polygon.setVisible(false);
                    vars.fieldShapes[id].overlay.hide();
                }
            });
        };

        _mapFieldService.showAllFields = function() {
            highlightEnabled = true;
            this.fieldId = null;
            Object.keys(vars.fieldShapes).forEach(id => {
                vars.fieldShapes[id].polygon.setVisible(true);
                vars.fieldShapes[id].overlay.show();
            });
        };

        _mapFieldService.clearSpray = function() {
            if (startMarker) {
                startMarker.setMap(null);
                startMarker = null;
            }
            scoutGrid.forEach(c => {
                if (c.cellRect) {
                    c.cellRect.setMap(null);
                }
                c.setMap(null);
            });
            combinedCells.forEach(p => {
                p.setMap(null);
            });
            scoutPoints.forEach(p => {
                p.setMap(null);
            });
            if (baseLine) {
                baseLine.setMap(null);
                baseLine = null;
            }
            if (baseLineP1) {
                baseLineP1.setMap(null);
                baseLineP1 = null;
            }
            if (baseLineP2) {
                baseLineP2.setMap(null);
                baseLineP2 = null;
            }
            if (drawingManager) {
                drawingManager.setDrawingMode(null);
                drawingManager.setMap(null);
            }
        };

        _mapFieldService.getScoutGrid = function() {
            return scoutGrid;
        };

        _mapFieldService.getStartPoint = function() {
            return startMarker.getPosition();
        };

        _mapFieldService.getStartPointAngle = function() {
            return google.maps.geometry.spherical.computeHeading(startMarker.getPosition(), scoutGrid.center);
        };

        _mapFieldService.updateDangerCellsFromSnapshot = function (snapshots) {
            if (snapshots) {
                scoutGrid.forEach(cell => {
                    cell.hasDanger = false;
                    cell.danger = null;

                    snapshots.forEach(snapshot => {
                        if (cell.loadIndex[0] == snapshot[0] && cell.loadIndex[1] == snapshot[1]) {
                            cell.hasDanger = true;
                            cell.danger = snapshot[2];
                        }
                    });
                });
            }
        };

        _mapFieldService.updateDangerPoints = function() {
            scoutPoints.forEach(p => {
                var icon = _.clone(SCOUT_POINT_ICON);
                scoutGrid.forEach(cell => {
                    if (p.cellIndex) {
                        var cellIndex = p.cellIndex;
                        if (cellIndex[0] == cell.loadIndex[0] && cellIndex[1] == cell.loadIndex[1]) {
                            if (cell.hasDanger) {
                                if (cell.danger == "warning") {
                                    icon.fillColor = "#DBD62A";
                                } else {
                                    icon.fillColor = "#D86D2F";
                                }
                            }
                        }
                    } else {
                        console.log("warning: missing cellIndex");
                    }
                });
                p.setOptions({icon: icon});
            });
        };

        _mapFieldService.getCellsDanger = function() {
            var result = [];
            scoutGrid.forEach(cell => {
                if (cell.hasDanger) {
                    result.push([cell.loadIndex[0], cell.loadIndex[1], cell.danger]);
                }
            });
            return result;
        };

        _mapFieldService.updateDangerCells = function(dangerMap) {
            scoutGrid.forEach(cell => {
                cell.hasDanger = false;
                cell.danger = null;

                if (!_.isUndefined(dangerMap.warning)) {
                    dangerMap.warning.forEach(warning => {
                        if (warning[0] == cell.loadIndex[0] && warning[1] == cell.loadIndex[1]) {
                            cell.hasDanger = true;
                            if (!cell.danger) {
                                cell.danger = 'warning';
                            }
                        }
                    });
                }

                if (!_.isUndefined(dangerMap.danger)) {
                    dangerMap.danger.forEach(danger => {
                        if (danger[0] == cell.loadIndex[0] && danger[1] == cell.loadIndex[1]) {
                            cell.hasDanger = true;
                            cell.danger = 'danger';
                        }
                    });
                }
            });
        };

        _mapFieldService.selectBadCells = function() {
            scoutGrid.forEach(cell => {
                if (cell.hasDanger && _mapFieldService.testCellOnField(cell.loadIndex)) {
                    cell.selected = true;
                    cell.setOptions(SELECTED_CELL);
                } else {
                    cell.selected = false;
                    cell.setOptions({fillOpacity: 0});
                }
            });
        };

        _mapFieldService.selectCellsWithAreaGreaterThan = function(areaLimit, fieldId) {
            scoutGrid.forEach(cell => {
                var fieldPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(_mapService.vars.fieldShapes[fieldId].polygon.getPath())));
                var cellPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(cell.getPath())));
                var cellOnField = fieldPolygon.intersection(cellPolygon);
                var area1 = google.maps.geometry.spherical.computeArea(cell.getPath());
                var area2 = google.maps.geometry.spherical.computeArea(jts2Path(cellOnField.getCoordinates()));

                if ((Math.abs(area2 / area1) > areaLimit) && _mapFieldService.testCellOnField(cell.loadIndex)) {
                    cell.selected = true;
                    cell.setOptions(SELECTED_CELL);
                } else {
                    cell.selected = false;
                    cell.setOptions({fillOpacity: 0});
                }
            });
        };

        _mapFieldService.drawTaskGrid = function(gridData, task) {
            scoutGrid = [];
            var p = new google.maps.LatLng(gridData.lat0, gridData.lon0);

            var color = CONSTANT.GRID_CELL_COLOR;
            if (task && task.gridGroupSize > 1) {
                color = CONSTANT.GRID_PALE_CELL_COLOR;
            }

            for (var i = 0; i < gridData.nx; i++) {
                for (var j = 0; j < gridData.ny; j++) {
                    var p1 = _mapFieldService.getP1(j,i,gridData); //row col
                    var p2 = _mapFieldService.getP2(j,i,gridData); //row col
                    var p3 = _mapFieldService.getP3(j,i,gridData); //row col
                    var p4 = _mapFieldService.getP4(j,i,gridData); //row col

                    var cell = new google.maps.Polygon({map: _mapService.vars.gmap, path: [p1, p2, p3, p4, p1], options: {strokeColor: color, strokeWeight: 1, fillOpacity: 0, zIndex: CONSTANT.ZINDEX.GRID}});
                    cell.loadIndex = [j, i, 0];
                    scoutGrid.push(cell);
                }
            }

            scoutGrid.center = google.maps.geometry.spherical.computeOffset(p, gridData.dy * gridData.ny / 2, gridData.angle + 90);
            scoutGrid.center = google.maps.geometry.spherical.computeOffset(scoutGrid.center, gridData.dx * gridData.nx / 2, gridData.angle);

            if (task && task.gridGroupSize > 1) {
                _.each(task.points, function (r) {
                    var col = r.affectedCells[0][1];
                    var minRow = _.min(_.map(r.affectedCells, function(e){ return e[0]; }));
                    var maxRow = _.max(_.map(r.affectedCells, function(e){ return e[0]; }));
                    var p1 = _mapFieldService.getP1(minRow,col,task.grid); //row col
                    var p2 = _mapFieldService.getP2(minRow,col,task.grid); //row col
                    var p3 = _mapFieldService.getP3(maxRow,col,task.grid); //row col
                    var p4 = _mapFieldService.getP4(maxRow,col,task.grid); //row col
                    combinedCells.push(new google.maps.Polyline({map: _mapService.vars.gmap, path: [p1, p2, p3, p4, p1], options: {strokeColor: CONSTANT.GRID_CELL_COLOR, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID_GROUP}}));
                });
            }
        };

        _mapFieldService.drawLoadStart = function(gridData, viewSpray) {
            var p = new google.maps.LatLng(gridData.lat0, gridData.lon0);
            START_ICON.rotation = google.maps.geometry.spherical.computeHeading(p, scoutGrid.center) + 90;

            if (viewSpray) {
                p = new google.maps.LatLng(viewSpray.startPoint.point.coordinates[0], viewSpray.startPoint.point.coordinates[1]);
                START_ICON.rotation = viewSpray.startPoint.angle + 90;
            }

            startMarker = new google.maps.Marker({
                position: p,
                map: _mapService.vars.gmap,
                draggable: true,
                crossOnDrag: false,
                icon: START_ICON,
                zIndex: CONSTANT.ZINDEX.START_MARKER});

            google.maps.event.addListener(startMarker, "drag", function (pp) {
                START_ICON.rotation = google.maps.geometry.spherical.computeHeading(this.getPosition(), scoutGrid.center) + 90;
                this.setIcon(START_ICON);
            });
        };

        _mapFieldService.enableCellClick = function() {
            scoutGrid.forEach(cell => {
                google.maps.event.addListener(cell, "click", function () {
                    if (this.selected) {
                        this.selected = false;
                        this.setOptions({fillOpacity: 0});
                    } else {
                        if (_mapFieldService.testCellOnField(this.loadIndex)) {
                            this.selected = true;
                            this.setOptions(SELECTED_CELL);
                        }
                    }
                });
            });
        };

        _mapFieldService.testCellOnField = function(loadIndex) {
            var cell = _.find(scoutGrid, function(c){ return c.loadIndex[0] == loadIndex[0] && c.loadIndex[1] == loadIndex[1]; });

            var fieldPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(_mapService.vars.fieldShapes[fieldId].polygon.getPath())));
            var cellPolygon = geometryFactory.createPolygon(geometryFactory.createLinearRing(path2JTS(cell.getPath())));
            var cellOnField = fieldPolygon.intersection(cellPolygon);

            return  google.maps.geometry.spherical.computeArea(jts2Path(cellOnField.getCoordinates())) > CONSTANT.EPS;
        };

        _mapFieldService.disableCellClick = function() {
            scoutGrid.forEach(cell => {
                google.maps.event.clearListeners(cell, "click");
            });
        };


        _mapFieldService.enableStartMarker = function() {
            startMarker.setDraggable(true);
        };

        _mapFieldService.fixStartMarker = function() {
            startMarker.setDraggable(false);
        };

        _mapFieldService.drawTaskResults = function(results) {
            if (results) {
                scoutPoints = [];
                results.forEach(r => {

                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng(r.lat, r.lon),
                        map: _mapService.vars.gmap,
                        icon: SCOUT_POINT_ICON,
                        zIndex: CONSTANT.ZINDEX.POINTS + r.pointIndex,
                        cellIndex: r.cellIndex
                    });
                    scoutPoints.push(marker);
                });
            }
        };


        var photoNotesMarkers = [];
        _mapFieldService.drawPhotoNotes = function(photoNotes) {

            _.each(photoNotesMarkers, function(m) {m.setMap(null)});
            photoNotesMarkers = [];

            _.each(photoNotes, function(photoNote) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(photoNote.lat, photoNote.lon),
                    map: _mapService.vars.gmap,
                    icon: '/img/photoNote.png',
                    zIndex: CONSTANT.ZINDEX.POINTS,
                    id: photoNote.id
                });

                marker.images = [];
                _.each(photoNote.results, function(result) {
                    marker.images.push(result.media);
                });

                google.maps.event.addListener(marker, "click", function() {
                    $rootScope.$safeApply(function() {
                        _v.change({set: {viewPhotoNote: marker.id, viewCropMonitor: undefined,  addCropMonitor: undefined, viewSpray: undefined, editSpray: undefined}});
                    });
                });
                if (_.size(marker.images) > 0) {
                    google.maps.event.addListener(marker, 'mouseover', function () {
                        var tooltip = d3.select(".photonote-tooltip");
                        var topRight = _mapService.vars.gmap.getProjection().fromLatLngToPoint(_mapService.vars.gmap.getBounds().getNorthEast());
                        var bottomLeft = _mapService.vars.gmap.getProjection().fromLatLngToPoint(_mapService.vars.gmap.getBounds().getSouthWest());
                        var worldPoint = _mapService.vars.gmap.getProjection().fromLatLngToPoint(marker.getPosition());
                        var scale = Math.pow(2, _mapService.vars.gmap.getZoom());
                        tooltip.style('left', (worldPoint.x - bottomLeft.x) * scale + 'px');
                        tooltip.style('top', (worldPoint.y - topRight.y) * scale + 'px');
                        tooltip.style("width", "45px");
                        if (_.size(marker.images) == 1) {
                            tooltip.select(".img-1").attr("src", marker.images[0]);
                            tooltip.select(".img-1").style("visibility", "visible");
                        } else if (_.size(marker.images) > 1) {
                            tooltip.style("width", "50px");
                            tooltip.select(".img-1").attr("src", marker.images[0]);
                            tooltip.select(".img-2").attr("src", marker.images[1]);
                            tooltip.select(".img-1").style("visibility", "visible");
                            tooltip.select(".img-2").style("visibility", "visible");
                        }

                        tooltip.style("visibility", "visible");
                    });

                    google.maps.event.addListener(marker, 'mouseout', function () {
                        d3.select(".photonote-tooltip").style("visibility", "hidden");
                        d3.select(".photonote-tooltip").selectAll("*").style("visibility", "hidden");
                    });
                }
                photoNotesMarkers.push(marker);
            });
        };

        var baseLine = null;
        var baseLineP1 = null;
        var baseLineP2 = null;

        var BASELINE_MARKER_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 5, strokeColor: "#F56423", strokeWeight: 2, fillColor: "#FFFFFF", fillOpacity: 1};

        var fieldId = null;


        _mapFieldService.startBaselineMode = function(fId, viewSpray) {
            fieldId = fId;

            if (baseLine) {
                baseLine.setMap(null)
            }
            if (baseLineP1) {
                baseLineP1.setMap(null)
            }
            if (baseLineP2) {
                baseLineP2.setMap(null)
            }

            baseLine = null;
            baseLineP1 = null;
            baseLineP2 = null;

            var baseLineMarkerOptions = {
                crossOnDrag: false,
                draggable: true,
                icon: BASELINE_MARKER_ICON,
                zIndex: CONSTANT.ZINDEX.BASELINE_MARKER,
                map: _mapService.vars.gmap
            };

            if (viewSpray) {
                var gridData = viewSpray.grid;
                baseLineP1 = new google.maps.Marker(baseLineMarkerOptions);
                baseLineP1.setPosition(new google.maps.LatLng(gridData.lat0, gridData.lon0));
                baseLineP2 = new google.maps.Marker(baseLineMarkerOptions);
                baseLineP2.setPosition(google.maps.geometry.spherical.computeOffset(baseLineP1.getPosition(), gridData.dx * gridData.nx, gridData.angle));
                baseLine = new google.maps.Polyline({
                    map: _mapService.vars.gmap,
                    path: [baseLineP1.getPosition(), baseLineP2.getPosition()],
                    options: {strokeColor: "#F56423", strokeWeight: 3},
                    zIndex: CONSTANT.ZINDEX.BASELINE
                });
                google.maps.event.addListener(baseLineP1, "drag", baseLineMove);
                google.maps.event.addListener(baseLineP2, "drag", baseLineMove);
                _mapFieldService.drawLoadStart(gridData, viewSpray);
                baseLineMove();
            } else {
                drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: google.maps.drawing.OverlayType.MARKER,
                    drawingControl: false,
                    drawingControlOptions: {
                        drawingModes: [google.maps.drawing.OverlayType.MARKER]
                    },
                    markerOptions: {
                        crossOnDrag: false,
                        draggable: true,
                        icon: BASELINE_MARKER_ICON,
                        zIndex: CONSTANT.ZINDEX.BASELINE_MARKER
                    }

                });
                drawingManager.setMap(_mapService.vars.gmap);

                google.maps.event.addListener(drawingManager, "markercomplete", function (p) {
                    if (!baseLineP1) {
                        baseLineP1 = p;
                        google.maps.event.addListener(p, "drag", baseLineMove);
                    } else {
                        if (!baseLineP2) {
                            baseLineP2 = p;
                            baseLine = new google.maps.Polyline({
                                map: _mapService.vars.gmap,
                                path: [baseLineP1.getPosition(), baseLineP2.getPosition()],
                                options: {strokeColor: "#F56423", strokeWeight: 3},
                                zIndex: CONSTANT.ZINDEX.BASELINE
                            });
                            google.maps.event.addListener(p, "drag", baseLineMove);
                            drawingManager.setMap(null);
                            baseLineMove();
                        }
                    }
                });
            }

        };

        _mapFieldService.enableBaseLine = function() {
            if (baseLine) {
                baseLineP1.setVisible(true);
                baseLineP2.setVisible(true);
                baseLine.setVisible(true);
            }
        };

        _mapFieldService.disableBaseLine = function() {
            if (baseLine) {
                baseLineP1.setVisible(false);
                baseLineP2.setVisible(false);
                baseLine.setVisible(false);
            }
        };

        var bandwidth = null;

        _mapFieldService.setBandWidth = function(b) {
            bandwidth = b;
            if (baseLine) {
                baseLineMove();
            }
        };

        _mapFieldService.tryHTML5geolocation = function() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    vars.gmap.setCenter(pos);
                    vars.gmap.setZoom(10);
                }, function() { });
            }
        };


        function baseLineMove() {
            baseLine.setPath([baseLineP1.getPosition(), baseLineP2.getPosition()]);

            var field = _mapService.vars.fieldShapes[fieldId];
            var center = getPolygonBounds(field.polygon).getCenter();
            var signedArea = google.maps.geometry.spherical.computeSignedArea([baseLineP1.getPosition(), baseLineP2.getPosition(), center]);

            var gridData = {lat0: baseLineP1.getPosition().lat(), lon0: baseLineP1.getPosition().lng()};
            gridData.angle = google.maps.geometry.spherical.computeHeading(baseLineP1.getPosition(), baseLineP2.getPosition());
            if (signedArea > 0) {
                gridData.lat0 = baseLineP2.getPosition().lat();
                gridData.lon0 = baseLineP2.getPosition().lng();
                gridData.angle = google.maps.geometry.spherical.computeHeading(baseLineP2.getPosition(), baseLineP1.getPosition());
            }



            gridData.dx = google.maps.geometry.spherical.computeDistanceBetween(baseLineP1.getPosition(), baseLineP2.getPosition());
            gridData.dy = bandwidth;

            gridData.nx = 1;

            var maxDist = 0;
            for (var i = 0; i < field.polygon.getPath().getLength(); i++) {
                var pathP = field.polygon.getPath().getAt(i);
                var signedArea2 = google.maps.geometry.spherical.computeSignedArea([baseLineP1.getPosition(), baseLineP2.getPosition(), pathP]);
                if (signedArea2 * signedArea > 0) {
                    var xxx = _mapFieldService.projectPointOnLine(baseLineP1.getPosition(), baseLineP2.getPosition(), pathP);
                    var dist = google.maps.geometry.spherical.computeDistanceBetween(xxx, pathP);
                    if (dist > maxDist) {
                        maxDist = dist;
                        gridData.ny = Math.ceil(maxDist / gridData.dy);
                    }
                }
            }

            scoutGrid.forEach(c => {
                if (c.cellRect) {
                    c.cellRect.setMap(null);
                }
                c.setMap(null);
            });

            _mapFieldService.drawTaskGrid(gridData);

            if (!startMarker) {
                _mapFieldService.drawLoadStart(gridData);
            }

            scoutGrid.forEach(cell => {
                cell.hasDanger = true;
            });

            $rootScope.$safeApply(() => {
                $rootScope.$broadcast('mapFieldService:baseLineCompleted', gridData);
            });
        }


        initEditOverlay();
        initTextOverlay();
        initNDVIOverlay();
        return _mapFieldService;

        function highlightField (field) {
            /**
             * Draw field
             */
            _mapService.drawField(field);

            /**
             * Attach events, add overlay, etc
             */
            if (_.has(vars.fieldShapes, field.id)) {
                var shape = vars.fieldShapes[field.id];
                shape.overlay = new TextOverlay(shape.polygon, field, vars.gmap);

                if (shape.shape == CONSTANT.POLYGON) { //polygon coordinates edit callback.
                    google.maps.event.addListener(shape.polygon.getPath(), 'set_at', () => fieldChanged(field.id));
                    google.maps.event.addListener(shape.polygon.getPath(), 'insert_at', () => fieldChanged(field.id));
                }

                if (shape.shape == CONSTANT.CIRCLE) { //gCircle edit callback.
                    setupUtils(shape);
                }

                google.maps.event.addListener(shape.polygon, "click", function () {
                    if (editModel.editMap) {
                        editField(this.fieldId);
                    }

                    $rootScope.$safeApply(() => $rootScope.$broadcast('mapService:fieldClick', [{fieldId: this.fieldId}]));
                });

                google.maps.event.addListener(shape.polygon, "mouseover", function () {
                    if (highlightEnabled) {
                        if (shape.polygon.disabelHighlight) {
                            return;
                        }
                        this.setOptions({fillOpacity: CONSTANT.OPACITY_FIELD_HOVER});
                    }
                });

                google.maps.event.addListener(shape.polygon, "mouseout", function () {
                    if (highlightEnabled) {
                        if (shape.polygon.disabelHighlight) {
                            return;
                        }
                        this.setOptions({fillOpacity: CONSTANT.OPACITY_FIELD});
                    }
                });

                if (editModel.editMap) {
                    editField(field.id);
                }
            }
        }

        function editFieldOff() {
            if (editModel.editFieldId) { //unedit previous field
                var fieldShape = vars.fieldShapes[editModel.editFieldId];
                if (fieldShape) {
                    if (fieldShape.shape == CONSTANT.POLYGON) {
                        fieldShape.polygon.setEditable(false);
                    }
                    if (fieldShape.shape == CONSTANT.CIRCLE) {
                        fieldShape.setEditable(false);
                    }
                    fieldShape.overlay.show();
                    fieldShape.editOverlay.setMap(null);
                    delete fieldShape.editOverlay;
                }
                editModel.editFieldId = null;
            }
        }

        function editField(fieldId) {
            editFieldOff();

            var fieldShape = vars.fieldShapes[fieldId];
            if (fieldShape.shape == CONSTANT.POLYGON) {
                fieldShape.polygon.setEditable(true);
            }
            if (fieldShape.shape == CONSTANT.CIRCLE) {
                fieldShape.setEditable(true);
            }
            fieldShape.overlay.hide();
            fieldShape.editOverlay = new EditOverlay(fieldShape.polygon, fieldShape.fieldId, vars.gmap);

            editModel.editFieldId = fieldId;
        }

        function deleteField (field) {
            var fieldShape = vars.fieldShapes[field.id];

            fieldShape.polygon.setMap(null);
            if (fieldShape.shape == CONSTANT.CIRCLE) {
                fieldShape.setMap(null);
            }
            delete _mapService.vars.fieldShapes[field.id];

            fieldShape.editOverlay.setMap(null);
            fieldShape.overlay.setMap(null);
        }

        function setTool (tool) {
            if (tool == 'hand') {
                drawingManager.setDrawingMode(null);
            }
            if (tool == 'poly') {
                drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
            }
            if (tool == 'circle') {
                drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
            }
        }

        function editMap () {
            drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: null,
                drawingControl: false,
                drawingControlOptions: {
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.CIRCLE]
                },

                polygonOptions: {
                    strokeColor: CONSTANT.COLOR_FIELD_STROKE,
                    fillOpacity: CONSTANT.OPACITY_FIELD,
                    editable: true
                },
                circleOptions: {
                    strokeColor: CONSTANT.COLOR_FIELD_STROKE,
                    fillOpacity: CONSTANT.OPACITY_FIELD,
                    editable: true
                }
            });
            drawingManager.setMap(vars.gmap);

            google.maps.event.addListener(drawingManager, "polygoncomplete", (polygon) => {
                if (polygon.getPath().getLength() > 2) {
                    polygon.setOptions(CONSTANT.fieldPolyOptions);
                    $rootScope.$broadcast('mapService:fieldCompleted', [{shape: CONSTANT.POLYGON, object: polygon}]);
                } else {
                    polygon.setMap(null);
                }
            });

            google.maps.event.addListener(drawingManager, "circlecomplete", (circle) => {
                var gCircle = _mapService.circleToGCircle(circle);
                setupUtils(gCircle);
                circle.setMap(null);
                $rootScope.$broadcast('mapService:fieldCompleted', [{shape: CONSTANT.CIRCLE, object: gCircle}]);
            });

            editModel.editMap = true;
        }

        function editMapOff() {
            editFieldOff();
            editModel.editMap = false;
            drawingManager.setDrawingMode(null);
            drawingManager.setMap(null);
        }

        /**
         * Edit Overlay
         *
         * @param polygon
         * @param fieldId
         * @param map
         * @constructor
         */
        function EditOverlay(polygon, fieldId, map) {
            // Initialize all properties.
            this.polygon = polygon;
            this.fieldId = fieldId;
            this.setMap(map);
            this.div = null;
        }

        function initEditOverlay() {

            EditOverlay.prototype = new google.maps.OverlayView();

            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            EditOverlay.prototype.onAdd = function () {
                var div = this.div = document.createElement('div');
                div.className = 'field-label';

                var compliedHTML = $compile("<c-edit-field-panel field-id='" + this.fieldId + "'></c-edit-field-panel>")($rootScope);
                $(div).append(compliedHTML);

                google.maps.event.addListener(div, 'click', function(mouseEvent) {
                    mouseEvent.stop();
                    //Handle the click here
                });

                //disable map general div events on this panel
                ["mousedown", "click", "dblclick"].forEach(eventName => {
                    div.addEventListener(eventName, e => e.stopPropagation());
                });

                div.addEventListener("click", function() {
                    if (compliedHTML.isolateScope().opened) {
                        div.classList.add('field-label--active');
                    } else {
                        div.classList.remove('field-label--active');
                    }
                });

                var panes = this.getPanes();
                panes.overlayMouseTarget.appendChild(div);
            };

            EditOverlay.prototype.draw = function () {
                var overlayProjection = this.getProjection();
                var c = overlayProjection.fromLatLngToDivPixel(getPolygonTop(this.polygon));
                var zoom = this.map.getZoom();

                if (zoom < 8) {
                    this.div.style.display = 'none';
                } else {
                    this.div.style.display = 'block';
                    this.div.style.left = (c.x - 20) + 'px';
                    this.div.style.bottom = -(c.y - 5) + 'px';
                }
            };

            // The onRemove() method will be called automatically from the API if we ever set the overlay's map property to 'null'.
            EditOverlay.prototype.onRemove = function () {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            };
        }

        /**
         * TextOverlay
         *
         * @param polygon
         * @param field
         * @param map
         * @constructor
         */
        function TextOverlay(polygon, field, map) {
            // Initialize all properties.
            this.polygon = polygon;
            this.fieldName = field.name;
            this.fieldId = field.id;
            this.farmId = field.farm ? field.farm.id : undefined;
            this.setMap(map);
            this.div = null;
            this.warning = null;
            this.warningVisible = false;
            this.visible = true;

        }

        function initTextOverlay() {
            TextOverlay.prototype = new google.maps.OverlayView();

            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            TextOverlay.prototype.onAdd = function () {
                var div = document.createElement('div');
                div.className = 'field-label';
                div.innerHTML = this.fieldName;

                this.warning = document.createElement('span');
                this.warning.className = 'field-warning';
                this.warning.style.cursor = 'pointer';
                div.appendChild(this.warning);
                if (this.warningVisible) {
                    this.warning.style.display = "block";
                }

                this.div = div;
                var panes = this.getPanes();
                panes.overlayMouseTarget.appendChild(div);
                var _this = this;
                google.maps.event.addDomListener(this.warning, 'click', function() {
                    var url = _v.getUrl({set: {view: 'w', field: _this.fieldId, pfield: undefined, farm: _this.farmId,
                        granularity: 'w', from: _this.firstWarningDate.format("YYYY-MM-DDTHH")}});
                    _flashService.put('forecastDiseaseId', _this.diseaseId);
                    window.location.href = url;
                });
            };

            TextOverlay.prototype.draw = function () {
                if (!this.div) {
                    return;
                }
                var overlayProjection = this.getProjection();
                var c = overlayProjection.fromLatLngToDivPixel(getPolygonTop(this.polygon));
                var zoom = this.map.getZoom();

                if (zoom < 8 || !this.visible) {
                    this.div.style.display = 'none';
                } else {
                    this.div.style.display = 'block';
                    this.div.style.left = (c.x - 20) + 'px';
                    this.div.style.bottom = -(c.y - 5) + 'px';
                }
            };

            // The onRemove() method will be called automatically from the API if we ever set the overlay's map property to 'null'.
            TextOverlay.prototype.onRemove = function () {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            };

            TextOverlay.prototype.hide = function() {
                this.visible = false;
                this.draw();
            };

            TextOverlay.prototype.show = function() {
                this.visible = true;
                this.draw();
            };

            TextOverlay.prototype.showWarning = function(d, diseaseId) {
                this.warningVisible = true;
                this.firstWarningDate = d;
                this.diseaseId = diseaseId;
                if (this.warning) {
                    this.warning.style.display = "block";
                }
            };

        }

        var ndviOverlay;
        var ndviData = [];

        function setNDVIData(data) {
            ndviData = data;
        }

        function switchNDVIOverlay(on, fieldId, from) {
            console.log("switchNDVIOverlay", on, fieldId, from ? from.format() : '');

            if (ndviOverlay) {
                ndviOverlay.setMap(null);
            }
            Object.keys(vars.fieldShapes).forEach(id => {
                vars.fieldShapes[id].polygon.setOptions({fillOpacity: CONSTANT.OPACITY_FIELD});
                vars.fieldShapes[id].polygon.disabelHighlight = false;
            });

            if (on) {
                var fieldShape = vars.fieldShapes[fieldId];

                var dd = null;
                _.each(ndviData, function (e) { //find latest image
                    if (e.actual) {
                        //console.log(moment.utc(e.acquisitionDate), "is before from", from);
                        if (moment.utc(e.acquisitionDate).isBefore(from)) {
                            //console.log("true");
                            if (dd == null) {
                                dd = e;
                            }
                            if (moment.utc(e.acquisitionDate).isAfter(moment.utc(dd.acquisitionDate))) {
                                dd = e;
                            }
                        }

                    }
                });

                console.log("selected ndvi: ", dd);

                fieldShape.polygon.setOptions({fillOpacity: 0});
                fieldShape.polygon.disabelHighlight = true;

                if (dd) {
                    console.log("selected ndvi date: ", moment.utc(dd.acquisitionDate).format());
                    var coordinates = JSON.parse(dd.coloredEnvelope).coordinates;
                    var bounds = new google.maps.LatLngBounds(
                        new google.maps.LatLng(coordinates[0][1], coordinates[0][0]),
                        new google.maps.LatLng(coordinates[1][1], coordinates[1][0]));

                    ndviOverlay = new NDVIOverlay(bounds, dd.media, _mapService.vars.gmap);
                }
            } else {
                ndviData = [];
            }
        }


        /** @constructor */
        function NDVIOverlay(bounds, image, map) {

            // Initialize all properties.
            this.bounds_ = bounds;
            this.image_ = image;
            this.map_ = map;

            // Define a property to hold the image's div. We'll
            // actually create this div upon receipt of the onAdd()
            // method so we'll leave it null for now.
            this.div_ = null;

            // Explicitly call setMap on this overlay.
            this.setMap(map);
        }

        function initNDVIOverlay() {

            NDVIOverlay.prototype = new google.maps.OverlayView();

            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            NDVIOverlay.prototype.onAdd = function() {
                var div = document.createElement('div');
                div.style.borderStyle = 'none';
                div.style.borderWidth = '0px';
                div.style.position = 'absolute';

                // Create the img element and attach it to the div.
                var img = document.createElement('img');
                img.src = this.image_;
                img.style.width = '100%';
                img.style.height = '100%';
                img.style.position = 'absolute';
                div.appendChild(img);

                this.div_ = div;

                // Add the element to the "overlayLayer" pane.
                var panes = this.getPanes();
                panes.overlayLayer.appendChild(div);
                panes.overlayLayer.style['zIndex'] = CONSTANT.ZINDEX.FIELD;
            };

            NDVIOverlay.prototype.draw = function() {

                // We use the south-west and north-east
                // coordinates of the overlay to peg it to the correct position and size.
                // To do this, we need to retrieve the projection from the overlay.
                var overlayProjection = this.getProjection();

                // Retrieve the south-west and north-east coordinates of this overlay
                // in LatLngs and convert them to pixel coordinates.
                // We'll use these coordinates to resize the div.
                var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
                var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

                // Resize the image's div to fit the indicated dimensions.
                var div = this.div_;
                div.style.left = sw.x + 'px';
                div.style.top = ne.y + 'px';
                div.style.width = (ne.x - sw.x) + 'px';
                div.style.height = (sw.y - ne.y) + 'px';
            };

            // The onRemove() method will be called automatically from the API if
            // we ever set the overlay's map property to 'null'.
            NDVIOverlay.prototype.onRemove = function() {
                this.div_.parentNode.removeChild(this.div_);
                this.div_ = null;
            };

        }

        function getPolygonTop(polygon) {
            var path = polygon.getPath();
            var result = path.getAt(0);

            for (var i = 0; i < path.getLength(); i++) {
                var p = path.getAt(i);
                if (p.lat() > result.lat()) {
                    result = p;
                }
            }

            return result
        }

        function getPolygonBounds(polygon) {
            var bounds = new google.maps.LatLngBounds();
            var paths = polygon.getPaths();
            var path;
            for (var p = 0; p < paths.getLength(); p++) {
                path = paths.getAt(p);
                for (var i = 0; i < path.getLength(); i++) {
                    bounds.extend(path.getAt(i));
                }
            }
            return bounds;
        }

        function polygonToGeoJSON(p) {
            var result = { "type": "Polygon", "coordinates": []};
            var path = p.getPath();
            var c = [];
            for(var i = 0; i < path.getLength(); i++) {
                var point = path.getAt(i);
                c.push([point.lng(), point.lat()]);
            }
            if (path.getLength() > 0) {
                var point = path.getAt(0);
                c.push([point.lng(), point.lat()]);
            }
            result.coordinates.push(c);
            return result;
        }

        function addCoordinates(jsonField, fieldId) {
            var fieldShape = vars.fieldShapes[fieldId];
            jsonField.shape = fieldShape.shape;
            if (fieldShape.shape == CONSTANT.CIRCLE) {
                jsonField.coordinates = polygonToGeoJSON(fieldShape.polygon);
                var controlPointsPolygon = new google.maps.Polygon();
                var path = [];
                fieldShape.markers.forEach(marker => {
                    path.push(marker.c1.getPosition());
                    path.push(marker.getPosition());
                    path.push(marker.c2.getPosition());
                });
                controlPointsPolygon.setPath(path);
                jsonField.controlPoints = polygonToGeoJSON(controlPointsPolygon);
            }
            if (fieldShape.shape == CONSTANT.POLYGON) {
                jsonField.coordinates = polygonToGeoJSON(fieldShape.polygon);
            }
        }

        function setupUtils(circleObject) {
            circleObject.updateArea = function() {
                fieldChanged(circleObject.fieldId);
            }
        }

        function fieldChanged(fieldId) {
            vars.fieldShapes[fieldId].overlay.draw();
            $rootScope.$broadcast('mapService:fieldChanged', [{fieldId: fieldId}]);
        }

        function panToLatLngZoom(bean) {
            vars.gmap.setZoom(parseInt(bean.zoom));
            vars.gmap.setCenter(new google.maps.LatLng(bean.lat, bean.lng));
        }


        function path2JTS(boundaries) {
            var coordinates = [];
            if (boundaries.getLength() > 0) {
                for (var i = 0; i < boundaries.getLength(); i++) {
                    var p = boundaries.getAt(i);
                    coordinates.push(new jsts.geom.Coordinate(p.lat(), p.lng()));
                }
                var p = boundaries.getAt(0);
                coordinates.push(new jsts.geom.Coordinate(p.lat(), p.lng()));
            }

            return coordinates;
        }

        function jts2Path(coordinates) {
            var result = [];
            for (var i = 0; i < coordinates.length; i++) {
                var c = coordinates[i];
                result.push(new google.maps.LatLng(c.x, c.y));
            }
            return result;
        }
    });